<template>
    <div class="manifesto">
        <div class="section">
            <h2>Manifesto</h2>
            <ul>
                <li>
                    <p>I enjoy the process of creating and innovating. If you can build a car out of legos, what can you build with software?</p>
                </li>
                <li>
                    <p>I aspire to help change the world for the better in any way possible.</p>
                </li>
                <li>
                    <p>I love experimenting and learning new technologies to see what ideas and solutions can be made.</p>
                </li>
                <li>
                    <p>Sometimes the world is a simple algorithm, othertimes it is a missing semi-colon.</p>
                </li>
            </ul>
        </div>
    </div>
</template>
